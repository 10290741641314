import {useAlterBox} from "../../../Modals/AlertModal/Context";
import {useRef, useState} from "react";
import DummyData from "../../dashboard/tables/ClientTable/varaibales/DummyData";
import PageTitle from "../../../components/utils/PageTitle";
import {Link} from "react-router-dom";
import {AiOutlineAppstoreAdd} from "react-icons/ai";
import TableCell from "../../../components/Table/TableCell";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableRow from "../../../components/Table/TableRow";
import Avatar from "../../../components/Image/Avatar/Avatar";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import {MdDelete, MdEditNote} from "react-icons/md";
import Badge from "../../../components/Badges/Badge";
import TableContainer from "../../../components/Table/TableContainer";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableBody from "../../../components/Table/TableBody";
import ReactStars from "react-rating-stars-component";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {toast} from "react-toastify";
import {truchateString} from "../../../utils/utils";


export default function ReviewsTable({className, ...props}) {

    const {alterBoxConfig, actions} = useAlterBox({
        title: "Delete Review",
        message: "Are you sure you want to delete this Review?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: onConfirm,
        onCancel: onCancle,

    });
    const selectedId = useRef(null);
    let [ReviewData, filter, isLoading, ReviewActions] = useListApi(URLS.reviewsList);


    function onConfirm() {
        if (selectedId.current === null) return;
        axiosInstance.delete(URLS.reviews + `/${selectedId.current}`, {
            data: {
                id: selectedId.current
            }
        }).then(res => {
            toast.success("Review deleted successfully")
        }).catch(err => {
            console.log(err)
            toast.error(err.response.data?.message || err?.message || "Something went wrong");
        }).finally(() => {
            ReviewActions.load()
            selectedId.current = null;
        })

    }

    function onCancle() {

    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <PageTitle>
                Reviews
            </PageTitle>
            <div className={"w-full flex mb-10 justify-end items-center"}>
                <Link title={"Add property"}
                      className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                      to={"/app/reviews/add"}>
                    <AiOutlineAppstoreAdd className={"text-base"}/>
                </Link>
            </div>

            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>From</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Review</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Actions</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {ReviewData?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && ReviewData?.results.map((Reviews, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <div>
                                            <p className="text-base text-gray-600  dark:text-gray-400">{Reviews.review_from}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{Reviews?.country}</p>

                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{
                                        truchateString(Reviews?.review, 8)
                                    }</p>

                                </TableCell>
                                <TableCell>
                                    <ReactStars
                                        value={Reviews?.rating}
                                        edit={false}
                                        count={5}
                                        size={24}
                                        classNames={"ml-1.5 -mt-4 "}
                                        activeColor="#ffd700"
                                    />
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{Reviews?.created_at?.split("-")?.[0]}</span>
                                </TableCell>
                                <TableCell>
                                    <div className={"flex gap-2"}>
                                        <Link to={`/app/reviews/edit/${Reviews.id}`}>
                                            <MdEditNote
                                                title={"edit"} className={"cursor-pointer"}/>
                                        </Link>
                                        <MdDelete title={"delete"}
                                                  onClick={() => {
                                                      selectedId.current = Reviews.id;
                                                      actions.open()
                                                  }}
                                                  className={"cursor-pointer"}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {ReviewData?.count} entries
                    </p>
                    <Paginator
                        currentPage={ReviewData?.currentPage}
                        totalPages={ReviewData?.totalPages}
                        onNext={(e) => {
                            ReviewActions.loadNextPage()
                        }}
                        onPrev={(e) => ReviewActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            ReviewActions.loadSpecificPage(e)
                        }}
                    />

                </TableFooter>

            </TableContainer>
        </div>

    )
}