import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import RichTextInput from "../../../components/RichTextInput/RichTextInput";
import React, {useEffect, useState} from "react";
import Categories from "../variables/categories";
import ReactStars from "react-rating-stars-component";
import {WithContext as ReactTags} from 'react-tag-input';
import "../index.css"
import useForm from "../../../Hooks/useForm";
import FormButton from "../../../components/Form/Button/FormButton";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";


export default function AddReview({className, ...props}) {
    let {id} = useParams();

    const [form, error, loading, setForm, actions] = useForm({});
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        if (!id) return;
        setFetching(true)
        axiosInstance.get(URLS.reviews + "/" + id).then(res => {
            setForm(res.data);
        }).catch(error => {
            toast.error(error?.response?.data?.error?.message || "An error occurred")
            navigate(-1);
        }).finally(() => setFetching(false))
    }, [])

    let SubmitForm = () => {
        if (loading || fetching) return;
        if (id) {
            actions.put(URLS.reviews + `/${id}`).then(res => {
                toast.success("Review updates successfully");
                navigate(-1);
            }).catch(error => {
                toast.error(error?.response?.data?.error?.message || "An error occurred")
            })

            return;
        }
        actions.post(URLS.reviews).then(res => {
            toast.success("Review created successfully");
            navigate(-1);

        }).catch(error => {

            toast.error(error?.response?.data?.error?.message || "An error occurred")
        })
    };


    if (fetching) return (<div className={`w-full h-full overflow-auto ${className}`}>

        <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
            <PageTitle>
                {
                    id ? "Edit " : "Add "
                } Review
            </PageTitle>
            <p>Loading...</p>
        </FormCard>
    </div>)

    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <PageTitle>
                    {
                        id ? "Edit " : "Add "
                    } Review
                </PageTitle>

                <div className={"grid grid-cols-1  gap-2 lg:gap-4"}>
                    <FormItem
                        label={"From"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.review_from}
                    >
                        <input
                            defaultValue={form?.review_from}
                            placeholder={"Who is the review from?"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, review_from: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Country"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.country}
                    >
                        <input
                            defaultValue={form?.country}
                            placeholder={"Country"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, country: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Review"}
                        type={"error"}

                        variant={"auth"}
                        message={error?.review}
                    >
                        <textarea
                            defaultValue={form?.review}
                            placeholder={"Write the review here"}
                            required={true}
                            onChange={(e) => {
                                setForm({...form, review: e.target.value})
                            }}
                        ></textarea>
                    </FormItem>
                    <label
                        htmlFor={"rating"}
                        className={`text-sm ml-1.5 font-medium text-gray-500 dark:text-white `}
                    >
                        Rating
                    </label>

                    <ReactStars
                        id={"rating"}
                        value={form?.rating}

                        onChange={(newRating) => {
                            setForm({...form, rating: newRating})
                        }}
                        count={5}
                        size={24}
                        classNames={"ml-1.5 -mt-4 "}
                        activeColor="#ffd700"
                    />
                    {
                        error?.rating && <p className={"text-red-500 text-xs"}>{error?.rating}</p>
                    }

                </div>

                <FormButton
                    loading={loading || fetching}
                    onClick={SubmitForm}

                >
                    Create Review
                </FormButton>
            </FormCard>

        </div>

    )
}