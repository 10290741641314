import BlogHeroSection from "../components/blog-hero-section";
import BlogCard, {BlogCardAnimation} from "../components/blog-card";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import {getUri} from "../../../utils/utils";
import {IoIosSad} from "react-icons/io";
import Paginator from "../../../components/Table/Paginator";


export default function BlogPage({className, ...props}) {
    let [guideData, filter, isDataLoading, guideActions] = useListApi(URLS.blogList, {
        page_size: 16,
        query: "",
    });



    return (<div className={"flex dark:text-white flex-col"}>
            <BlogHeroSection
                onChange={(event) => {
                    guideActions.updateFilter({query: event.target.value})
                }}
            />
            <div className={"px-[80px] flex flex-col gap-[32px] py-[30px]"}>
                <p className={"font-bold"}>
                    All blog posts
                </p>
                <div className={"grid grid-cols-1 gap-[32px] md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4"}>
                    {isDataLoading && <>
                        {
                            Array(13).fill(0).map((_, index) => (
                                <BlogCardAnimation key={index} className={"w-full"}/>
                            ))
                        }
                    </>
                    }
                    {
                        guideData?.results?.map((item, index) => {
                            return <BlogCard
                                key={index}
                                refreshCallBack={guideActions?.refresh}
                                title={item?.title}
                                metaDescription={item.meta_description}
                                date={item?.updated_at}
                                thumbnail={getUri(item.thumbnail?.file)}
                            />
                        })
                    }


                </div>
                {
                    guideData?.totalPages ?
                        <div className={"w-full justify-center items-center flex "}>

                            <Paginator
                                className={"mt-10"}
                                currentPage={guideData?.currentPage}
                                totalPages={guideData?.totalPages}
                                onNext={(e) => {
                                    guideActions.loadNextPage()
                                }}
                                onPrev={(e) => guideActions.loadPrevPage()}
                                onPageNumberClick={(e) => {
                                    guideActions.loadSpecificPage(e)
                                }}
                            />

                        </div> : <></>
                }
                {
                    !isDataLoading && guideData?.results?.length === 0 &&
                    <div className={"flex flex-col my-12 gap-2 items-center"}>
                        <IoIosSad className={"text-4xl"}/>


                        <p className={"font-bold text-xl"}>
                            Total {guideData?.count} results found
                        </p>
                    </div>
                }

            </div>
        </div>

    )
}