import {Route, Routes} from "react-router-dom";
import AddCoupon from "./screens/addCoupon";
import CouponTable from "./screens/couponTable";

export default function Coupons({className, ...props}) {

    return (
        <Routes>
            <Route path="/" element={<CouponTable/>}/>
            <Route path="/add" element={<AddCoupon/>}/>
            <Route path="/edit/:id" element={<AddCoupon/>}/>
        </Routes>

    )
}