import {Route, Routes} from "react-router-dom";
import AddReview from "./screens/addReview";
import ReviewsTable from "./screens/reviewsTable";

export default function Reviews({className, ...props}) {

    return (
        <Routes>
            <Route path="/" element={<ReviewsTable/>}/>
            <Route path="/add" element={<AddReview/>}/>
            <Route path="/edit/:id" element={<AddReview/>}/>
        </Routes>

    )
}