import {GrFormNext, GrFormPrevious} from "react-icons/gr";

export default function Paginator({
                                      className,
                                      currentPage,
                                      totalPages,
                                      onClickPage,
                                      onClickNext,
                                      onClickPrev,
                                      onNext,
                                      onPrev,
                                      onPageNumberEnter,
                                      onPageNumberClick,

                                      ...props
                                  }) {


    function SliceArrayIntoTwoParts(currentPage, totalPages) {
        let pivot = totalPages > 5 ? 5 : totalPages - 1;

        let pages = new Array(totalPages).fill(0).map((item, index) => index + 1);
        if (pages.length > pivot) {
            let firstPart = pages.slice(currentPage - 1, currentPage + pivot)
            while (firstPart.length < pivot) {
                firstPart.unshift(firstPart[0] - 1)
            }
            let secondPart = [pages.length]

            return [firstPart, secondPart]
        }
        return [[], []]

    }

    function containsEnd() {
        return SliceArrayIntoTwoParts(currentPage, totalPages)[0].includes(totalPages);

    }


    return (
        <div className={`flex justify-center text-xl items-center gap-2 ${className}`}>

            {<GrFormPrevious className={"text-secondary-900 cursor-pointer"}
                             onClick={() => {
                                 if (currentPage > 1) {

                                     onPrev?.()
                                 }
                             }}
            />}
            <div className={"flex flex-row gap-0 justify-center items-center"}>
                {
                    SliceArrayIntoTwoParts(currentPage, totalPages)[0].map((item, index) => {

                        return (
                            <p key={index}
                               onClick={() => onPageNumberClick(item)}
                               className={`rounded-md cursor-pointer  ${currentPage === item ? "bg-purple-900 text-white" : "text-black"} text-sm px-2 py-1 text-center`}>
                                {item}
                            </p>
                        )
                    })
                }
            </div>
            {
                //     if the difference between the current page and the total pages is greater than 5
                //     then we will add a ... to indicate that there are more pages
                //     else we will not add the ...

                !containsEnd() ? <p>...</p> : null
            }

            <div className={"flex flex-row gap-0 justify-center items-center"}>
                {
                    !containsEnd() && SliceArrayIntoTwoParts(currentPage, totalPages)[1].map((item, index) => {

                        return (
                            <p key={index}
                               onClick={() => onPageNumberClick(item)}

                               className={`rounded-md cursor-pointer  ${currentPage === item ? "bg-purple-900 text-white" : "text-black"} text-sm px-2 py-1 text-center`}>
                                {item}
                            </p>
                        )
                    })
                }
            </div>
            {<GrFormNext
                onClick={() => {
                    if (currentPage < totalPages) {

                        onNext?.()
                    }
                }}
                className={"text-secondary-900 cursor-pointer"}/>}
            <input
                type={"number"}
                defaultValue={currentPage}
                onChange={(e) => {

                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if (isNaN(parseInt(e.target.value))) {
                            return
                        }
                        if (e.target.value < totalPages) {
                            onPageNumberClick(parseInt(e.target.value))
                        }
                    }
                }}
                className={"w-7 h-7 text-md mr-4 rounded-md text-center border-[1px] border-gray-300"}
            />
        </div>

    )
}