import PageTitle from "../../../components/utils/PageTitle";
import FormCard from "../../../components/utils/FormCard";
import FormItem from "../../../components/Form/FormItem/FormItem";
import RichTextInput from "../../../components/RichTextInput/RichTextInput";
import React, {useEffect, useState} from "react";
import Categories from "../variables/categories";
import ReactStars from "react-rating-stars-component";
import {WithContext as ReactTags} from 'react-tag-input';
import "../index.css"
import useForm from "../../../Hooks/useForm";
import FormButton from "../../../components/Form/Button/FormButton";
import URLS from "../../../API/Axios/URLS";
import {toast} from "react-toastify";
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import Checkbox from "../../../components/checkbox";
import Switch from "../../../components/switch";


export default function AddCoupon({className, ...props}) {
    let {id} = useParams();

    const [form, error, loading, setForm, actions] = useForm({
        is_active: true,
    });
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);


    useEffect(() => {
        if (!id) return;
        setFetching(true)
        axiosInstance.get(URLS.coupon + "/" + id).then(res => {
            setForm(res.data);
        }).catch(error => {
            toast.error(error?.response?.data?.error?.message || "An error occurred")
            navigate(-1);
        }).finally(() => setFetching(false))
    }, [])

    let SubmitForm = () => {
        if (loading || fetching) return;
        if (id) {
            actions.put(URLS.coupon + `/${id}`).then(res => {
                toast.success("coupon updates successfully");
                navigate(-1);
            }).catch(error => {
                toast.error(error?.response?.data?.error?.message || "An error occurred")
            })

            return;
        }
        actions.post(URLS.coupon).then(res => {
            toast.success("coupon created successfully");
            navigate(-1);

        }).catch(error => {

            toast.error(error?.response?.data?.error?.message || "An error occurred")
        })
    };


    if (fetching) return (<div className={`w-full h-full overflow-auto ${className}`}>

        <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
            <PageTitle>
                {
                    id ? "Edit " : "Add "
                } Coupon
            </PageTitle>
            <p>Loading...</p>
        </FormCard>
    </div>)

    return (
        <div className={`w-full h-full overflow-auto ${className}`}>

            <FormCard className={"mt-8 px-4 lg:px-8  pb-8"}>
                <div className={"flex items-center justify-between"}>

                    <PageTitle>
                        {
                            id ? "Edit " : "Add "
                        } Coupon
                    </PageTitle>

                    <Switch
                        checked={form?.is_active}
                        onChange={(e) => {
                            setForm({...form, is_active: e.target.checked})
                        }}
                        dataValues={['Active', 'Inactive']}
                    />
                </div>

                <div className={"grid grid-cols-1  gap-2 lg:gap-4"}>
                    <FormItem
                        label={"Coupon Code"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.coupon_code}
                    >
                        <input
                            defaultValue={form?.coupon_code}
                            placeholder={"Coupon code"}
                            required={true}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, coupon_code: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Sold to"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.sold_to}
                    >
                        <input
                            value={form?.sold_to}
                            placeholder={"Sold to"}
                            required={true}
                            pattern={"[0-9]+"}
                            type={"text"}
                            onChange={(e) => {
                                setForm({...form, sold_to: e.target.value})
                            }}
                        />
                    </FormItem>
                    <FormItem
                        label={"Sold At (If Sold)"}
                        type={"error"}
                        variant={"auth"}
                        message={error?.sold_at}
                    >
                        <input
                            defaultValue={
                                form?.sold_at && new Date(form?.sold_at).toISOString().split("T")?.[0] || ""
                            }
                            placeholder={"Sold At"}
                            required={false}
                            type={"date"}
                            onChange={(e) => {
                                setForm({...form, sold_at: e.target.value})
                            }}
                        />
                    </FormItem>

                </div>
                <div className={"my-2 px-2 flex gap-2"}>
                    <Checkbox
                        type={"checkbox"}
                        id={"is_sold"}
                        checked={form?.is_sold}
                        onChange={(e) => {
                            setForm({...form, is_sold: e.target.checked})
                        }}
                    />
                    <label htmlFor={"is_sold"}>
                        Is Sold
                    </label>
                </div>
                {
                    error?.is_sold && <p className={"text-red-500 text-xs"}>{error?.is_sold}</p>
                }

                <FormButton
                    loading={loading || fetching}
                    onClick={SubmitForm}

                >
                    {
                        id ? "Edit " : "Add "

                    } Coupon
                </FormButton>
            </FormCard>

        </div>

    )
}