import "./index.css"
import {useId} from "react";

export default function Switch({className, ...props}) {
    const id = useId()
    const [onValue, offValue] = props?.dataValues || ["Active", "Blocked"]
    return (

        <div className="checkbox-wrapper-8">
            <input type="checkbox"
                   {...props}
                   id={id} className="tgl tgl-skewed"/>
            <label htmlFor={id} data-tg-on={onValue} data-tg-off={offValue} className="tgl-btn"/>
        </div>

    )
}