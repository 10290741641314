import {useAlterBox} from "../../../Modals/AlertModal/Context";
import {useRef, useState} from "react";
import DummyData from "../../dashboard/tables/ClientTable/varaibales/DummyData";
import PageTitle from "../../../components/utils/PageTitle";
import {Link} from "react-router-dom";
import {AiOutlineAppstoreAdd, AiOutlineUpload} from "react-icons/ai";
import TableCell from "../../../components/Table/TableCell";
import TableEmpty from "../../../components/Table/TableEmpty";
import TableLoadingRow from "../../../components/Table/TableLoadingRow";
import TableRow from "../../../components/Table/TableRow";
import Avatar from "../../../components/Image/Avatar/Avatar";
import TableFooter from "../../../components/Table/TableFooter";
import Paginator from "../../../components/Table/Paginator";
import {MdDelete, MdEditNote} from "react-icons/md";
import Badge from "../../../components/Badges/Badge";
import TableContainer from "../../../components/Table/TableContainer";
import Table from "../../../components/Table/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableBody from "../../../components/Table/TableBody";
import ReactStars from "react-rating-stars-component";
import useListApi from "../../../Hooks/useListApi";
import URLS from "../../../API/Axios/URLS";
import axiosInstance from "../../../API/Axios/AxiosConfig";
import {toast} from "react-toastify";
import {truchateString} from "../../../utils/utils";
import TabSwitch from "../../../components/TabSwitch/TabSwitch";


export default function CouponsTable({className, ...props}) {

    const {alterBoxConfig, actions} = useAlterBox({
        title: "Delete Coupon",
        message: "Are you sure you want to delete this Coupon?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: onConfirm,
        onCancel: onCancle,

    });
    const selectedId = useRef(null);
    let [ReviewData, filter, isLoading, ReviewActions] = useListApi(URLS.couponList);


    function onConfirm() {
        if (selectedId.current === null) return;
        axiosInstance.delete(URLS.coupon + `/${selectedId.current}`, {
            data: {
                id: selectedId.current
            }
        }).then(res => {
            toast.success("Review deleted successfully")
        }).catch(err => {
            console.log(err)
            toast.error(err.response.data?.message || err?.message || "Something went wrong");
        }).finally(() => {
            ReviewActions.load()
            selectedId.current = null;
        })

    }

    function onCancle() {

    }

    const [uploading, setUploading] = useState(false);

    function onFileUpload(e) {
        if (uploading) return;
        console.log(e.target.files)
        let file = e.target.files[0];
        if (!file) return;
        setUploading(true);
        let formData = new FormData();

        formData.append("file", file);
        axiosInstance.post(URLS.couponFile, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            toast.success("File uploaded successfully")
            ReviewActions.load()
        }).catch(err => {
            toast.error(err.response.data?.error || err?.error || "Something went wrong");
        }).finally(() => {
            e.target.value = null;
            setUploading(false);
        })

    }


    return (
        <div className={`w-full h-full overflow-auto ${className}`}>
            <PageTitle>
                Coupons
            </PageTitle>

            <div className={"flex flex-col-reverse my-8 lg:my-0  gap-3 lg:flex-row justify-between items-center"}>
                <div className={"flex gap-4 my-8 flex-col"}>
                    <TabSwitch
                        onOptionChange={(value) => {
                            ReviewActions.updateFilter({
                                coupon_type: value
                            })
                        }}
                        options={[
                            {
                                label: "All",
                                value: "all"
                            },
                            {
                                label: "Sold",
                                value: "sold"
                            },
                            {
                                label: "Un Sold",
                                value: "unsold"
                            }
                        ]}
                    />
                    <TabSwitch
                        onOptionChange={(value) => {
                            ReviewActions.updateFilter({
                               active: value
                            })
                        }}
                        options={[
                            {
                                label: "All",
                                value: "all"
                            },
                            {
                                label: "Active",
                                value: "active"
                            },
                            {
                                label: "InActive",
                                value: "inactive"
                            }
                        ]}
                    />
                </div>
                <div className={"w-full flex mb-10 gap-4 justify-end items-center"}>
                    <input
                        id={"upload-coupon"}
                        accept={".xsl , .xlsx"}
                        onChange={onFileUpload}
                        type={"file"} hidden={true}/>
                    <label
                        htmlFor={"upload-coupon"}
                        title={"Upload Coupon File"}
                        className={"w-10 h-10 cursor-pointer rounded-full justify-center items-center flex  text-white bg-brand-800"}
                    >

                        <AiOutlineUpload className={"text-base"}/>

                    </label>
                    <Link
                        className={"w-10 h-10 rounded-full justify-center items-center flex  text-white bg-brand-800"}
                        to={"/app/coupons/add"}>
                        <AiOutlineAppstoreAdd className={"text-base"}/>
                    </Link>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableHeader>
                        <tr>
                            <TableCell>Coupon code</TableCell>
                            <TableCell>is Sold</TableCell>
                            <TableCell>Sold To</TableCell>
                            <TableCell>is Active</TableCell>
                            <TableCell>Sold at</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Action</TableCell>
                        </tr>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <TableLoadingRow/>}
                        {ReviewData?.results?.length === 0 && <TableEmpty/>}

                        {!isLoading && ReviewData?.results.map((Coupons, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <div className="flex items-center text-sm">
                                        <div>
                                            <p className="text-base text-gray-600  dark:text-gray-400">{Coupons?.coupon_code}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{Coupons?.is_sold ? "Sold" : "Available"}</p>

                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{
                                        Coupons?.sold_to || "-"
                                    }</p>

                                </TableCell>
                                <TableCell>
                                    <p className="text-sm text-gray-600  dark:text-gray-400">{Coupons?.is_active ? "Active" : "Not Active"}</p>

                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{Coupons?.sold_at &&
                                        new Date(Coupons?.sold_at).toLocaleDateString() || "-"
                                    }</span>
                                </TableCell>
                                <TableCell>
                                    <span className="text-sm">{Coupons?.created_at?.split("-")?.[0]}</span>

                                </TableCell>
                                <TableCell>
                                    <div className={"flex gap-2"}>
                                        <Link to={`/app/coupons/edit/${Coupons.id}`}>
                                            <MdEditNote
                                                title={"edit"} className={"cursor-pointer"}/>
                                        </Link>
                                        <MdDelete title={"delete"}
                                                  onClick={() => {
                                                      selectedId.current = Coupons.id;
                                                      actions.open()
                                                  }}
                                                  className={"cursor-pointer"}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TableFooter>
                    <p className={'flex-1 flex dark:text-gray-400 ml-4 text-xs items-center font-semibold tracking-wide uppercase'}>
                        {ReviewData?.count} entries
                    </p>
                    <Paginator
                        currentPage={ReviewData?.currentPage}
                        totalPages={ReviewData?.totalPages}
                        onNext={(e) => {
                            ReviewActions.loadNextPage()
                        }}
                        onPrev={(e) => ReviewActions.loadPrevPage()}
                        onPageNumberClick={(e) => {
                            ReviewActions.loadSpecificPage(e)
                        }}
                    />

                </TableFooter>

            </TableContainer>
        </div>

    )
}