import {BiHomeAlt} from 'react-icons/bi'
import {HiOutlineClipboardList} from 'react-icons/hi'
import {BsCardText} from 'react-icons/bs'
import {AiOutlineContacts, AiOutlineUser} from 'react-icons/ai'

import {FiSettings} from "react-icons/fi";
import {MdOutlineMenuBook, MdReviews} from "react-icons/md";
import {FaUserMd} from "react-icons/fa";
import {ImBooks} from "react-icons/im";

const routes = [
    {
        path: '/app/dashboard', // the url
        icon: BiHomeAlt, // the component being exported from icons/index.js
        name: 'Dashboard', // name that appear in Sidebar
    },
    {
        path: '/app/reviews', // the url
        icon: MdReviews, // the component being exported from icons/index.js
        name: 'Reviews', // name that appear in Sidebar
    },
    {
        path: '/app/coupons', // the url
        icon: BsCardText, // the component being exported from icons/index.js
        name: 'Det Coupons', // name that appear in Sidebar
    },
    {
        path: '/app/guide', // the url
        icon: MdOutlineMenuBook, // the component being exported from icons/index.js
        name: 'Guide', // name that appear in Sidebar
    },
    {
        path: '/app/user', // the url
        icon: AiOutlineUser, // the component being exported from icons/index.js
        name: 'user', // name that appear in Sidebar
    }, {
        path: '/app/blogs',
        icon: BsCardText,
        name: 'Blogs',
    }, , {
        path: '/app/material',
        icon: ImBooks,
        name: 'Material',
    },
    // {
    //     path: '/app/properties',
    //     icon: HiOutlineClipboardList,
    //     name: 'Properties',
    // },
    {
        path: '/app/news-letter-subscriber',
        icon: AiOutlineContacts,
        name: 'Update Subscriber',
    },
    {
        path: '/app/settings',
        icon: FiSettings,
        name: 'Settings',
    },


]

export default routes
